import Vue from "vue";
import VueRouter from "vue-router";
import login from "@/views/login.vue";
import notFound  from '@/views/404.vue'
import home from '@/views/home.vue'
import welcome from '@/views/welcome'

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => { })
}

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
    redirect: '/login',
    meta: {
      title: '登录',
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: '登录',
    },
  },
  {
    path: "/home",
    name: "home",
    component: home,
    meta: {
      title: '首页',
    },
    children: [
      {
        path: "/welcome",
        name: "welcome",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "welcome" */ "../views/welcome.vue"),
        meta: {
          title: '首页',
        },
      },
      {
        path: "/userManage/registerList",
        name: "registerList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "registerList" */ "../views/userManage/registerList.vue"),
        meta: {
          title: '注册列表',
        },
      },
      {
        path: "/userManage/registerList/rechargeRecord",
        name: "rechargeRecord",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "rechargeRecord" */ "../views/userManage/rechargeRecord.vue"),
          meta: {
            title: '充值明细',
          },
      },
      {
        path: "/statisticsManage/statisticsIndex",
        name: "statisticsIndex",
        component: () =>
          import(/* webpackChunkName: "meetingOrderList" */ "../views/statisticsManage/statisticsIndex.vue"),
          meta: {
            title: '统计',
          },
      },
      {
        path: "/meetingOrderManage/meetingOrderList",
        name: "meetingOrderList",
        component: () =>
          import(/* webpackChunkName: "meetingOrderList" */ "../views/meetingOrderManage/meetingOrderList.vue"),
          meta: {
            title: '订单收入统计',
          },
      },
      {
        path: "/deviceManage/deviceOrderList",
        name: "deviceOrderList",
        component: () =>
          import(/* webpackChunkName: "deviceOrderList" */ "../views/deviceManage/deviceOrderList.vue"),
          meta: {
            title: '设备订单',
          },
      },
      {
        path: "/meetingProblemManage/meetingProblemList",
        name: "meetingProblemList",
        component: () =>
          import(/* webpackChunkName: "meetingProblemList" */ "../views/meetingProblemManage/meetingProblemList.vue"),
          meta: {
            title: '直播故障',
          },
      },
      {
        path: "/sysUserManage/sysUserList",
        name: "sysUserList",
        component: () =>
          import(/* webpackChunkName: "sysUserList" */ "../views/sysUserManage/sysUserList.vue"),
          meta: {
            title: '人员管理',
          },
      },
      {
        path: "/sysManage/menuManage",
        name: "menuManage",
        component: () =>
          import(/* webpackChunkName: "menuManage" */ "../views/sysManage/menuManage.vue"),
          meta: {
            title: '菜单管理',
          },
      },
      {
        path: "/sysManage/roleManage",
        name: "roleManage",
        component: () =>
          import(/* webpackChunkName: "roleManage" */ "../views/sysManage/roleManage.vue"),
          meta: {
            title: '角色管理',
          },
      },
      {
        path: "/flowFeeManage/flowFeeList",
        name: "flowFeeList",
        component: () =>
          import(/* webpackChunkName: "flowFeeList" */ "../views/flowFeeManage/flowFeeList.vue"),
          meta: {
            title: '价格管理',
          },
      },
    ]
  },
  {
    path: '*',
    name: 'notFound',
    component: notFound,
    meta: {
      title: '404'
    }
  }
];

const router = new VueRouter({
  routes,
});

router.afterEach( to => {
  document.title = to.meta.title
})
export default router;
