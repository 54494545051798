<template>
  <div class="home">
    <div class="left">
      <left-nav></left-nav>
    </div>
    <div class="right">
      <top-header></top-header>
      <div class="main">
        <transition>
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import topHeader from "@/components/layout/topHeader";
import leftNav from "@/components/layout/leftNav";
import { mapMutations } from 'vuex';
export default {
  data() {
    return {};
  },
  components: {
    topHeader,
    leftNav,
  },
  created() {
    this.initData()
  },
  methods: {
    ...mapMutations(['updateAuthorities']),
    /* 初始化数据 */
    initData() {
      const authorities = sessionStorage.getItem('authorities')
      if(authorities){
        this.updateAuthorities(JSON.parse(authorities))
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.home{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  .left{
    width: 200px;
  }
  .right{
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .main{
      flex: 1;
      min-width: 1000px;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
</style>
