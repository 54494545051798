import request from "../utils/request";
import qs from 'qs'

/* 删除新增消息通知 */
export const deleteMsgNoticeByIds = (params) => {
    return request.post(`/t/msg/notice/deleteByIds?${qs.stringify(params, { indices: false })}`)
}

/* 消息通知详情 */
export const getMsgNoticeDetail = (id) => {
    return request.get(`/t/msg/notice/detail/${id}`)
}

/* 消息通知集合查询 */
export const getMsgNoticeList = () => {
    return request.get("/t/msg/notice/list")
}

/* 消息通知分页查询 */
export const getMsgNoticePage = (params) => {
    return request.get(`/t/msg/notice/page?${qs.stringify(params, { indices: false })}`)
}

/* 新增消息通知 */
export const addMsgNotice = (params) => {
    return request.post(`/t/msg/notice/save?${qs.stringify(params, { indices: false })}`)
}

/* 修改消息通知 */
export const editMsgNotice = (params) => {
    return request.post(`/t/msg/notice/update?${qs.stringify(params, { indices: false })}`)
}