<template>
  <div class="left-nav">
    <div class="top">
      <img class="logo" :src="require('@/assets/image/logo.png')" alt="">
      <span class="title">会议管理系统</span> 
    </div>
    <el-menu
      :default-active="$route.path"
      background-color="rgb(48, 65, 86)"
      text-color="#fff"
      active-text-color="rgb(64, 158, 255)"
      @open="handleOpen"
      @close="handleClose"
      @select="handleMnenuSelect"
    >
      <template v-for="item in menuList">
        <menu-item :item="item" :key="item.id"></menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultActive: null,
      menuList: [
        /* {
          name: "统计",
          url: "/statisticsManage/statisticsIndex",
          icon: "el-icon-s-data",
          id: 11,
          children: [],
        },
        {
          name: "注册列表",
          url: "/userManage/registerList",
          icon: "el-icon-s-help",
          id: 12,
          children: [],
        },
        {
          name: "订单收入统计",
          url: "/meetingOrderManage/meetingOrderList",
          icon: "el-icon-s-marketing",
          id: 13,
          children: [],
        },
        {
          name: "设备订单",
          url: "/deviceManage/deviceOrderList",
          icon: "el-icon-s-order",
          id: 14,
          children: [],
        },
        {
          name: "直播故障",
          url: "/meetingProblemManage/meetingProblemList",
          icon: "el-icon-s-release",
          id: 15,
          children: [],
        },
        {
          name: "人员管理",
          url: "/sysUserManage/sysUserList",
          icon: "el-icon-s-custom",
          id: 16,
          children: [],
        },
        {
          name: "平台系统管理",
          url: "",
          icon: "el-icon-menu",
          id: 17,
          children: [
            {
              name: "角色管理",
              url: "/sysManage/roleManage",
              icon: "",
              id: 18,
              children: [],
            },
            {
              name: "菜单管理",
              url: "/sysManage/menuManage",
              icon: "",
              id: 19,
              children: [],
            },
          ],
        }, */
      ],
    };
  },
  watch: {
  },
  components: {
    "menu-item": {
      name: "menu-item",
      props: ["item"],
      template: `
        <div v-if="item.authority && item.authority.includes('BACK:#')">
          <el-submenu :index="item.url" v-if="item.url == '#' && (item.children && item.children.length > 0)" :key="item.id">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{item.name}}</span>
            </template>
            <template v-for="(subItem) in item.children">
              <menu-item :item="subItem"></menu-item>
            </template>
          </el-submenu>
          <el-menu-item :index="item.url" v-else :key="item.id">
            <i :class="item.icon"></i>
            <span slot="title">
              {{item.name}}
            </span>
          </el-menu-item>
        </div>
      `,
    },
  },
  computed: {},
  mounted() {
  },
  created(){
    let menuList = sessionStorage.getItem('menusList')
    if(menuList){
      this.menuList = JSON.parse(menuList)
    }
    console.log('this.menuList: ', this.menuList)
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    handleMnenuSelect(index, indexPath) {
      console.log(index, indexPath);
      this.$router.push({
        path: index,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.left-nav {
  height: 100vh;
  background-color: rgb(48, 65, 86);
  color: #ffffff;
  box-sizing: border-box;
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    .logo{
      width: 20px;
    }
    .title{
      margin-left: 6px;
    }
  }
  /deep/ {
    .el-menu{
      border: none !important;
    }
    .el-menu-item {
      text-align: left;
      &:hover {
        background-color: rgb(33, 44, 58) !important;
      }
    }
    .el-submenu__title {
      text-align: left;
      &:hover {
        background-color: rgb(33, 44, 58) !important;
      }
    }
  }
}
</style>