import request from "@/utils/request";
import qs from 'qs'

/* 查询当前是哪个会议有效期 */
export const getMeetingSetDetail = () => {
    return request.get('/t/meeting/validity/detail')
}

/* 有效期集合 */
export const getMeetingMap = () => {
    return request.get('/t/meeting/validity/map')
}

/* 设置会议有效期 */
export const setMeetingInfo = (params) => {
    return request.post(`/t/meeting/validity/set/validity?${qs.stringify(params, { indices: false })}`)
}