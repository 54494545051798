import request from "../utils/request";
import qs from 'qs'

/* 创建用户 */
export const createSysUser = (params) => {
    return request.post('/back/t/user/create/user', params)
}

/* 用户分页查询 */
export const sysUserList = (params) => {
    return request.post('/back/t/user/page/list', params)
}

/* 编辑用户 */
export const sysUserUpdate = (params) => {
    return request.post('/back/t/user/update/user', params)
}

/* 查询用户详情 */
export const sysUserDetail = (id) => {
    return request.post(`/back/t/user/select/info/${id}`)
}

/* 初始化用户密码 */
export const sysUserPasswordInit = (params) => {
    return request.post(`/back/t/user/pass/init?${qs.stringify(params, { indices: false })}`)
}

/* 用户有效无效 */
export const sysUserEnable = (params) => {
    return request.post(`/back/t/user/update/state?${qs.stringify(params, { indices: false })}`)
}


/* 删除用户 */
export const sysUserDel = (params) => {
    return request.post(`/back/t/user/deleteUser?${qs.stringify(params, { indices: false })}`)
}

/* 修改密码 */
export const editPassword = (params) => {
    return request.post(`/back/t/user/updateMyPassword?${qs.stringify(params, { indices: false })}`)
}