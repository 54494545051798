const OSS = require('ali-oss');
import { v4 as uuidv4 } from 'uuid';
import request from "@/utils/request";
import { bucket } from '@/config/index'
import { getFileExtname, getFileExtnameAll } from '@/utils/tool'
/* 获取oss文件上传参数 */
export const getOSSParams = (params) => {
    return request.post('/sts/upload', params)
}
/* 简单文件上传，不包含上传进度 */
export const ossSimpleFileUpload = (file) => {
    return new Promise(async (resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);
        try {
            let refreshToken = await getOSSParams()
            let { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: securityToken } = refreshToken;
            let ossClient = new OSS({
                accessKeyId,
                accessKeySecret,
                stsToken: securityToken,
                bucket,
                secure: true,
                folder: "",
                region: 'oss-cn-beijing',
                // 刷新临时访问凭证。
                refreshSTSToken: async () => {
                    const refreshToken = await getOSSParams();
                    return {
                        accessKeyId: refreshToken.AccessKeyId,
                        accessKeySecret: refreshToken.AccessKeySecret,
                        stsToken: refreshToken.SecurityToken,
                    };
                },
            });
            let uploadPath = `shufaClassPublic/sysManage/${sessionStorage.getItem('userId')}/` + uuidv4() + getFileExtnameAll(file.name)
            let result = await ossClient.put(uploadPath, file)
            resolve({
                fileName: file.name,
                fileUrl: result.res.requestUrls[0].split('?')[0]
            })
        } catch (err) {
            reject(err)
        }
    })
}

/* 大文件上传，包含上传进度 */
export const ossBigFileUpload = (file, processCall, cancelFunc) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);
        getOSSParams().then(res => {
            let { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: securityToken } = res;
            let ossClient = new OSS({
                accessKeyId,
                accessKeySecret,
                stsToken: securityToken,
                bucket,
                secure: true,
                folder: "",
                region: 'oss-cn-beijing',
                // 刷新临时访问凭证。
                refreshSTSToken: async () => {
                    const refreshToken = await getOSSParams();
                    return {
                        accessKeyId: refreshToken.AccessKeyId,
                        accessKeySecret: refreshToken.AccessKeySecret,
                        stsToken: refreshToken.SecurityToken,
                    };
                },
            });
            cancelFunc(ossClient.cancel)
            let uploadPath = `publicSysManage/${sessionStorage.getItem('userId')}/` + uuidv4() + getFileExtnameAll(file.name)
            ossClient.multipartUpload(uploadPath, file, {
                progress(e) {
                    processCall(parseInt(e * 100))
                },
            }).then(res => {
                resolve([{
                    fileName: file.name,
                    fileUrl: res.res.requestUrls[0].split('?')[0],
                    extname: getFileExtname(localFile.name),
                }])
            }).catch(err => {
                console.error('err: ', err)
                reject(err)
            })
        }).catch(err => {
            reject(err)
        })
    })
}
