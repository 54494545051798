<template>
  <div class="page">
    <img class="bg-img" :src="require('@/assets/image/welcome.png')" alt="">
    <div class="title">欢迎登录铭学通管理系统！</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    };
  },
  components: {},
  computed: {},
  mounted () {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.page{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .bg-img{
    margin-top: -40px;
    margin-left: -20px;
  }
  .title {
    margin-top: 40px;
    font-size: 30px;
    font-weight: 700;
  }
}
</style>