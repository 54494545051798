import request from "@/utils/request";

/* 登录相关 */

/* 登录接口 */
export const login = (params) => {
    return request.post('/common/login', params)
}

/* 登出接口 */
export const logout = () => {
    return request.get('/common/logout')
}


/* 登录时获取的code码 */
export const loginCode = () => {
    return request.get('/common/login/code')
}

/* 获取用户信息 */
export const getUserInfo = () => {
    return request.get('/common/t/user/getUserInfo')
}

/* 修改用户信息 */
export const updateUserInfo = (params) => {
    return request.post('/back/t/user/update', params)
}


