<template>
  <div class="header">
    <div class="header-right">
      <div class="notice-set"></div>
      <el-button
        class="label mR20"
        v-show="authorities.includes('BACK:MSG_NOTICE_PAGE')"
        type="text"
        @click="handleSetMsgNotice"
        >通知管理</el-button
      >
      <div class="meeting-set" v-show="authorities.includes('BACK:SET_MEETING_VALIDITY')">
        <el-button class="label" type="text" @click="handleSetMeenting"
          >会议过期设置</el-button
        >
        <!-- <span class="value" v-show="authorities.includes('BACK:MEETING_VALIDITY_DETAIL')">{{
          meetingValidity ? meetingValidityMap[meetingValidity] : "暂无设置"
        }}</span> -->
      </div>
      <div class="user-info" @click="handleSeeUserInfo">
        <img
          class="head-img"
          :src="
            userInfo.headImg
              ? userInfo.headImg
              : require('@/assets/image/default_head.png')
          "
          alt=""
        />
        <span class="user-name">{{ userInfo.username }}({{ userInfo.realName }})</span>
      </div>
      <div
        class="opt-item"
        v-show="authorities.includes('BACK:UPDATE_OURSELF_PASSWORD')"
        @click="handleEditPassword"
      >
        <i class="iconfont icon-mima"></i>
        <span class="item-txt">修改密码</span>
      </div>
      <div class="opt-item" @click="handleLogout">
        <i class="iconfont icon-tuichu"></i>
        <span class="item-txt">退出登录</span>
      </div>
    </div>
    <!-- 会议有效期设置 -->
    <el-dialog
      title="会议过期设置"
      :visible.sync="dialogMeetingSet"
      width="500px"
      class="dialog-meeting-set"
    >
      <div class="dialog-content">
        <span class="label">未点击开始会议课程有效</span>
        <el-select size="small" v-model="selectMeetintValidity" placeholder="请选择">
          <el-option
            v-for="item in meetingValidityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogMeetingSet = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmMeetingSet"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogEditPasswordData.show"
      width="500px"
      class="dialog-meeting-set"
    >
      <div class="dialog-content" style="padding-right: 10px">
        <el-form
          :model="dialogEditPasswordData"
          :rules="dialogEditPasswordDataRules"
          ref="editPassword"
          label-width="100px"
        >
          <el-form-item label="原密码" prop="oldPassword">
            <el-input
              v-model="dialogEditPasswordData.oldPassword"
              placeholder="请输入原密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model="dialogEditPasswordData.newPassword"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="reNewPassword">
            <el-input
              v-model="dialogEditPasswordData.reNewPassword"
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEditPasswordData.show = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmEditPassword"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 通知管理 -->
    <el-dialog
      title="消息通知"
      :visible.sync="msgNoticeData.show"
      width="800px"
      class="dialog-meeting-set"
    >
      <el-dialog
        width="400"
        :title="addMsgNoticeData.title"
        :visible.sync="addMsgNoticeData.show"
        append-to-body
      >
        <div style="padding-right: 20px">
          <el-form
            :model="addMsgNoticeData"
            :rules="addMsgNoticeDataRules"
            ref="addMsgNoticeData"
            label-width="100px"
          >
            <el-form-item label="消息内容" prop="content">
              <el-input
                type="textarea"
                rows="3"
                resize="none"
                v-model="addMsgNoticeData.content"
                placeholder="请输入消息内容"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="addMsgNoticeData.show = false">取 消</el-button>
          <el-button size="small" type="primary" @click="handleConfirmAddOrEditMsgNotice"
            >保 存</el-button
          >
        </span>
      </el-dialog>
      <div class="dialog-btn-area-msg">
        <el-button
          @click="handleAddMsgNotice"
          size="small"
          type="primary"
          icon="el-icon-plus"
          >新增</el-button
        >
        <el-button
          @click="handleEditMsgNotice('batch')"
          :disabled="msgMultipleSelection.length != 1"
          size="small"
          type="primary"
          icon="el-icon-edit"
          >编辑</el-button
        >
        <el-button
          @click="handleDelMsgNotice('batch')"
          :disabled="msgMultipleSelection.length == 0"
          size="small"
          type="primary"
          icon="el-icon-delete"
          >删除</el-button
        >
      </div>
      <div class="dialog-content-msg">
        <el-table
          height="100%"
          ref="msgListData"
          :data="msgNoticeData.list"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleMsgNoticeSelectionChange"
        >
          <el-table-column align="center" type="selection" width="55"> </el-table-column>
          <el-table-column prop="content" label="消息内容"> </el-table-column>
          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEditMsgNotice('row', scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="handleDelMsgNotice('row', scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialog-bottom">
        <el-pagination
          background
          @size-change="handleMsgNoticeSizeChange"
          @current-change="handleCurrentChange"
          :current-page="msgNoticeListParams.pageNum"
          :page-size="msgNoticeListParams.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="msgNoticeData.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="编辑用户信息" :visible.sync="dialogUserDataShow" width="500px">
      <div>
        <el-form
          ref="dialogUserData"
          size="small"
          :model="dialogUserData"
          :rules="dialogUserDataRules"
          label-width="100px"
        >
          <el-form-item label="用户头像" prop="headImg">
            <div class="img-select" @click="handleImgSelectClick">
              <img
                v-if="dialogUserData.headImg"
                class="add-head"
                :src="dialogUserData.headImg"
                alt=""
              />
              <span v-else class="el-icon-plus add-icon"></span>
            </div>
            <input
              type="file"
              @change="handleImgSelectChange"
              accept="image/*"
              ref="imgSelect"
              style="display: none"
            />
          </el-form-item>
          <el-form-item label="用户名" prop="username">
            <el-input
              disabled
              v-model="dialogUserData.username"
              placeholder="请输入用户名"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realName">
            <el-input
              v-model="dialogUserData.realName"
              placeholder="请输入真实姓名"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="dialogUserData.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogUserDataShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmUpdateUserInfo"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ossSimpleFileUpload } from "@/api/common";
import { getMeetingSetDetail, getMeetingMap, setMeetingInfo } from "@/api/meetingSet";
import { getUserInfo, updateUserInfo } from "@/api/index";
import {
  deleteMsgNoticeByIds,
  getMsgNoticePage,
  addMsgNotice,
  editMsgNotice,
} from "@/api/msgNotice";
import { editPassword } from "@/api/sysUser";
import { logout } from "@/api/index";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      dialogMeetingSet: false,
      meetingValidity: null,
      selectMeetintValidity: null,
      meetingValidityMap: {},
      meetingValidityList: [],
      /* 修改密码数据 */
      dialogEditPasswordData: {
        show: false,
        newPassword: "",
        oldPassword: "",
        reNewPassword: "",
      },
      dialogEditPasswordDataRules: {
        oldPassword: [
          { required: true, message: "原密码不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                return callback(new Error("原密码不能为空"));
              }
              return callback();
            },
          },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                return callback(new Error("新密码不能为空"));
              }
              return callback();
            },
          },
        ],
        reNewPassword: [
          { required: true, message: "确认密码不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                return callback(new Error("确认密码不能为空"));
              }
              return callback();
            },
          },
        ],
      },
      /* 通知管理数据 */
      msgMultipleSelection: [],
      msgNoticeListParams: {
        pageNum: 1,
        pageSize: 10,
      },
      msgNoticeData: {
        show: false,
        total: 0,
        list: [],
      },
      addMsgNoticeData: {
        title: "添加消息通知",
        show: false,
        id: null,
        content: "",
      },
      addMsgNoticeDataRules: {
        content: [{ required: true, message: "消息内容不能为空", trigger: "change" }],
      },
      dialogUserDataShow: false,
      dialogUserData: {
        headImg: "",
        realName: "",
        mobile: "",
        username: "",
      },
      dialogUserDataRules: {
        headImg: [{ required: true, message: "头像不能为空", trigger: "change" }],
        realName: [{ required: true, message: "真实姓名不能为空", trigger: "change" }],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (!/^1\d{10}$/.test(value)) {
                return callback(new Error("手机号码格式不正确！"));
              }
              return callback();
            },
          },
        ],
      },
    };
  },
  components: {},
  computed: {
    ...mapState(["authorities", "userInfo"]),
  },
  mounted() {
    this.getMeetingSetDetail();
    this.getMeetingMap();
    this.getUserInfo();
  },
  methods: {
    ...mapMutations(["updateUserInfo"]),
    /* 点击选择头像 */
    handleImgSelectClick() {
      this.$refs["imgSelect"].value = "";
      this.$refs["imgSelect"].click();
    },
    /* 处理选择了头像 */
    async handleImgSelectChange() {
      const file = this.$refs["imgSelect"].files[0];
      try {
        const { fileUrl } = await ossSimpleFileUpload(file);
        this.dialogUserData.headImg = fileUrl;
      } catch (err) {
        let msg = err.msg ? err.msg : "图片上传发生异常！";
        console.error("ossSimpleFileUpload: ", err);
        this.msgError(msg);
      }
    },
    /* 点击查看用户信息 */
    handleSeeUserInfo() {
      const { headImg, username, realName, mobile } = this.userInfo;
      this.dialogUserDataShow = true;
      this.dialogUserData = {
        headImg,
        username,
        realName,
        mobile,
      };
    },
    /* 保存用户信息 */
    handleConfirmUpdateUserInfo() {
      this.$refs["dialogUserData"].validate(async (valid) => {
        if (valid) {
          if (this._saveUserInfoFlag) {
            return;
          }
          this._saveUserInfoFlag = true;
          try {
            const { headImg, realName, mobile } = this.dialogUserData;
            const data = await updateUserInfo({
              headImg,
              realName,
              mobile,
              id: this.userInfo.id,
            });
            this._saveUserInfoFlag = false;
            if (!data) {
              return this.msgError("用户信息修改失败！");
            }
            this.updateUserInfo({
              headImg,
              realName,
              mobile,
            });
            this.dialogUserDataShow = false;
            this.msgSuccess("用户信息修改成功！");
          } catch (err) {
            this._saveUserInfoFlag = false;
            let msg = err.msg ? err.msg : "保存用户信息发生异常！";
            console.error("handleConfirmUpdateUserInfo err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 获取用户信息 */
    async getUserInfo() {
      try {
        const { realName, headImg, username, mobile, id } = await getUserInfo();
        this.updateUserInfo({
          realName,
          headImg,
          username,
          mobile,
          id,
        });
      } catch (err) {
        let msg = err.msg ? err.msg : "获取用户信息发生异常！";
        this.msgError(msg);
        console.error("getUserInfo: ", err);
      }
    },
    /* 消息通知选择发生改变 */
    handleMsgNoticeSelectionChange(val) {
      console.log('handleMsgNoticeSelectionChange: ', val)
      this.msgMultipleSelection = val;
    },
    /* 确定添加和修改消息通知 */
    async handleConfirmAddOrEditMsgNotice() {
      this.$refs["addMsgNoticeData"].validate(async (valid) => {
        if (valid) {
          if (this._addMsgNoticeFlag) {
            return;
          }
          this._addMsgNoticeFlag = true;
          const { content, id } = this.addMsgNoticeData;
          try {
            if (!id) {
              const data = await addMsgNotice({
                content,
              });
              if (!data) {
                return this.msgError("添加消息通知失败！");
              }
              this._addMsgNoticeFlag = false;
              this.msgSuccess("添加成功！");
              this.addMsgNoticeData.show = false;
              this.getMsgNoticeList();
            } else {
              const data = await editMsgNotice({
                content,
                id,
              });
              if (!data) {
                return this.msgError("修改消息通知失败！");
              }
              this._addMsgNoticeFlag = false;
              this.msgSuccess("修改成功！");
              this.addMsgNoticeData.show = false;
              this.getMsgNoticeList();
            }
          } catch (err) {
            this._addMsgNoticeFlag = false;
            let msg = err.msg ? err.msg : `${id ? "修改" : "添加"}消息通知发生异常！`;
            console.error("handleConfirmAddOrEditMsgNotice: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 处理新增消息通知 */
    handleAddMsgNotice() {
      this.addMsgNoticeData = {
        title: "添加消息通知",
        show: true,
        content: "",
        id: null,
      };
      this.$nextTick(() => {
        this.$refs["addMsgNoticeData"].clearValidate();
      });
    },
    /* 处理修改消息通知 */
    handleEditMsgNotice(type, row) {
      if (type == "batch") {
        row = this.msgMultipleSelection[0];
      }
      const { id, content } = row;
      this.addMsgNoticeData = {
        title: "修改消息通知",
        show: true,
        content,
        id,
      };
    },
    /* 处理删除消息通知 */
    handleDelMsgNotice(type, row) {
      let ids = [];
      if (type == "batch") {
        ids = this.msgMultipleSelection.map((item) => {
          return item.id;
        });
      } else if (type == "row") {
        ids = [row.id];
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const data = await deleteMsgNoticeByIds({
            ids,
          });
          if (!data) {
            return this.msgError("删除失败！");
          }
          this.msgSuccess("删除成功！");
          this.getMsgNoticeList();
        } catch (err) {
          let msg = err.msg ? err.msg : "删除消息通知列表发生异常！";
          console.error("deleteMsgNoticeByIds: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 消息通知单页数量改变 */
    handleMsgNoticeSizeChange(pageSize) {
      this.msgNoticeListParams.pageSize = pageSize;
      this.getMsgNoticeList();
    },
    /* 消息通知页码改变 */
    handleCurrentChange(pageNum) {
      this.msgNoticeListParams.pageNum = pageNum;
      this.getMsgNoticeList();
    },
    /* 获取消息通知列表 */
    async getMsgNoticeList() {
      try {
        const { total, records, pages, current } = await getMsgNoticePage(
          this.msgNoticeListParams
        );
        this.msgNoticeData.total = total;
        this.msgNoticeData.list = records;
        if (current > pages && pages) {
          this.msgNoticeListParams.pageNum = pages;
          this.getMsgNoticeList();
        }
      } catch (err) {
        let msg = err.msg ? err.msg : "获取消息通知列表发生异常！";
        console.error("getMsgNoticePage: ", err);
        this.msgError(msg);
      }
    },
    /* 处理修改密码 */
    handleEditPassword() {
      this.dialogEditPasswordData.show = true;
      this.$nextTick(() => {
        if (this.$refs["editPassword"]) {
          this.$refs["editPassword"].resetFields();
        }
      });
    },
    /* 确定修改密码 */
    handleConfirmEditPassword() {
      this.$refs["editPassword"].validate(async (valid) => {
        if (valid) {
          const { oldPassword, newPassword, reNewPassword } = this.dialogEditPasswordData;
          if (newPassword != reNewPassword) {
            return this.msgWarning("新密码和确认密码不一致！");
          }
          try {
            const data = await editPassword({
              oldPassword,
              newPassword,
            });
            if (!data) {
              return this.msgError("修改密码失败！");
            }
            this.msgSuccess("密码修改成功！");
            this.dialogEditPasswordData.show = false;
          } catch (err) {
            let msg = err.msg ? err.msg : "修改密码发生异常！";
            console.error("logout: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 处理退出登录 */
    handleLogout() {
      this.$confirm("确定要退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await logout();
          this.msgSuccess("退出成功！");
          this.$router.push({
            path: "/login",
          });
        } catch (err) {
          let msg = err.msg ? err.msg : "退出登录发生异常！";
          console.error("logout: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 点击消息管理 */
    handleSetMsgNotice() {
      this.msgNoticeListParams.pageNum = 1;
      this.getMsgNoticeList();
      this.msgNoticeData.show = true;
    },
    /* 获取会议设置详情 */
    async getMeetingSetDetail() {
      if (!this.authorities.includes("BACK:MEETING_VALIDITY_DETAIL")) {
        return;
      }
      try {
        const data = await getMeetingSetDetail();
        if(!data){
          return
        }
        this.meetingValidity = String(data.validity);
      } catch (err) {
        let msg = err.msg ? err.msg : "获取会议过期详情发生异常！";
        console.error("getMeetingSetDetail: ", err);
        this.msgError(msg);
      }
    },
    /* 设置会议过期时间 */
    handleSetMeenting() {
      this.dialogMeetingSet = true;
      this.selectMeetintValidity = this.meetingValidity;
      console.log(this.selectMeetintValidity, this.meetingValidityList);
    },
    /* 保存会议过期时间设置 */
    async handleConfirmMeetingSet() {
      if (!this.selectMeetintValidity) {
        return this.msgWarning("请先选择会议有限期！");
      }
      try {
        await setMeetingInfo({
          validity: this.selectMeetintValidity,
        });
        this.meetingValidity = this.selectMeetintValidity;
        this.dialogMeetingSet = false;
        this.msgSuccess("保存成功！");
      } catch (err) {
        let msg = err.msg ? err.msg : "设置会议有效期发生异常！";
        console.error("setMeetingInfo: ", err);
        this.msgError(msg);
      }
    },
    /* 获取会议有效期列表 */
    async getMeetingMap() {
      try {
        const meetingValidityMap = await getMeetingMap();
        let meetingValidityList = [];
        for (let key in meetingValidityMap) {
          meetingValidityList.push({
            id: key,
            name: meetingValidityMap[key],
          });
        }
        this.meetingValidityMap = meetingValidityMap;
        this.meetingValidityList = meetingValidityList;
        console.log(meetingValidityMap);
      } catch (err) {
        let msg = err.msg ? err.msg : "获取会议有效期列表发生异常！";
        console.error("getMeetingMap: ", err);
        this.msgError(msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.img-select {
  height: 84px;
  width: 84px;
  border: 2px solid #ebebeb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .add-head {
    width: 84px;
    height: 84px;
  }
  .add-icon {
    font-size: 30px;
  }
}
.header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .meeting-set {
      .value {
        margin: 0 20px 0 10px;
      }
    }
    .user-info {
      margin-left: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .head-img {
        width: 20px;
        height: 20px;
        border-radius: 20px;
      }
      .user-name {
        margin-left: 4px;
        color: #222222;
      }
    }
    .opt-item {
      margin-left: 10px;
      padding: 0 10px;
      color: #222222;
      cursor: pointer;
      .iconfont {
        color: #222222;
      }
      .item-txt {
        margin-left: 4px;
      }
      &:hover {
        color: rgb(64, 158, 255);
        .iconfont {
          color: rgb(64, 158, 255);
        }
      }
    }
  }
  .dialog-meeting-set {
    .dialog-content {
      .label {
        margin-right: 20px;
      }
    }
  }
  .dialog-btn-area-msg {
    height: 40px;
    text-align: right;
  }
  .dialog-content-msg {
    height: 50vh;
  }
  .dialog-bottom {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
