<template>
  <div class="_404">
    <div class="tip">抱歉，您访问的页面不存在！</div>
    <div class="go-login" @click="handleLogin">去登陆</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    };
  },
  components: {},
  computed: {},
  mounted () {},
  methods: {
    /* 去登陆 */
    handleLogin() {
        this.$router.push({
            path: '/'
        })
    },
  }
}
</script>
<style lang='scss' scoped>
._404{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .tip{
        font-size: 20px;
        
    }
    .go-login{
        color: blueviolet;
        cursor: pointer;
        margin-top: 20px;
        font-size: 40px;
    }
}
</style>