import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css'
import '@/assets/css/index.scss'
//import '@/assets/css/font/iconfont.css'
Vue.config.productionTip = false;

Vue.use(ElementUI);

// 自定义指令
import '@/directives/index.js'

Vue.prototype.msgSuccess = (...args) => {
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.success(...args)
}
Vue.prototype.msgInfo = (...args) => {
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.info(...args)
}
Vue.prototype.msgWarning = (...args) => {
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.warning(...args)
}
Vue.prototype.msgError = (...args) => {
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.error(...args)
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
