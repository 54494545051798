import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authorities: [],
    userInfo: {
      headImg: '',
      username: '',
      realName: '',
      mobile: '',
      id: '',
    },
  },
  mutations: {
    updateAuthorities(state, authorities){
      state.authorities = authorities
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = { ...state.userInfo, ...userInfo}
    },
  },
  actions: {},
  modules: {},
});
