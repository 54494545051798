const XLSX = require("xlsx");
/* 获取文件扩展名 */
export const getFileExtname = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if(lastIndexof){
        const extname = fileName.substring(lastIndexof + 1)
        return extname.toLowerCase()
    }
    return ''
}

/* 获取文件扩展名包含点 */
export const getFileExtnameAll = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if (lastIndexof) {
        const extname = fileName.substring(lastIndexof)
        return extname.toLowerCase()
    }
    return ''
}

/* 数据导出ecxcel [[111, 222, 333], [444, 555, 666]] */
export const dataToExcel = (data, fileName) => {
    const ws_name = "SheetJS";
    const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, fileName);
}

/* table元素导出excel */
export const tableToExcel = (tableDom, fileName) => {
    const wb = XLSX.utils.table_to_book(tableDom, { sheet: "Sheet JS" });
    XLSX.writeFile(wb, fileName);
}

/* 数据统计计算百分比 */
export const getPercent = (a, b) => {
    if(a == 0){
        return '0%'
    }
    if(b == 0){
        return '--'
    }
    return (Math.abs(a - b) / b * 100).toFixed(2) + '%'
}
/* 数据统计处理数字3位加逗号 */
export const getThreeNum = (a) => {
    return Number(a).toLocaleString()
}
/* 数据统计获取增加类型 */
export const getIncreaseType = (a , b) => {
    return a - b > 0 ? 1 : -1
}