<template>
  <div class="login-page">
    <div class="login-box">
      <div class="title">铭学通在线运营管理平台</div>
      <el-form ref="form" :model="saveData" :rules="rules">
        <el-form-item prop="username">
          <el-input
            class="input"
            placeholder="请输入用户名"
            prefix-icon="iconfont icon-mima"
            v-model="saveData.username"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            prefix-icon="iconfont icon-yonghuming"
            v-model="saveData.password"
            type="password"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <JcRange
            status=""
            :iscate="iscate"
            @success-fun="handleSuccessFun"
          ></JcRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loginLoading" class="login-btn" @click="handleGoToMain"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import JcRange from "@/components/JcRange";
import { login, loginCode } from "@/api/index";
import { encrypt } from "@/utils/rsaEncrypt";
export default {
  data() {
    return {
      iscate: false,
      loginLoading: false,
      saveData: {
        userType: 2,
        loginType: 1,
        //username: "admin1",
        //password: "123456",
        username: "",
        password: "",
        code: "",
        encode: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "change" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "change" },
        ],
      },
    };
  },
  components: {
    JcRange,
  },
  computed: {},
  mounted() {},
  methods: {
    handleGoToMain() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.iscate) {
            this.msgWarning("请先滑动解锁");
            return;
          }
          if(this.loginLoading){
            return
          }
          this.loginLoading = true
          try {
            const code = await loginCode();
            this.loginLoading = false
            this.saveData.code = code;
            this.saveData.encode = encrypt(code);
            const { token, userId, authorities, menusList, realName } =
              await login(this.saveData);
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("userId", userId);
            sessionStorage.setItem("menusList", JSON.stringify(menusList));
            sessionStorage.setItem("realName", realName);
            sessionStorage.setItem("authorities", JSON.stringify(authorities));
            this.$router.push({
              path: "/welcome",
            });
          } catch (err) {
            this.loginLoading = false
            let msg = err.msg ? err.msg : "登录发生异常！";
            console.error("login: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccessFun() {
      this.iscate = true;
    },
  },
};
</script>
<style lang='scss' scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/image/login-bg.jpg");
  background-size: cover;
  .login-box {
    width: 400px;
    height: 362px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px 20px;
    .title {
      line-height: 60px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    /deep/ {
      .el-form-item__content {
        line-height: 46px;
      }
      .el-input__inner {
        height: 46px;
        line-height: 46px;
      }
      .el-input__icon {
        line-height: 46px;
      }
    }
    .input {
      height: 46px;
    }
    .login-btn {
      height: 46px;
      width: 100%;
      font-size: 18px;
    }
  }
}
</style>